define("discourse/plugins/discourse-spoiler-alert/initializers/spoiler-alert", ["exports", "discourse/lib/plugin-api", "discourse/controllers/composer", "discourse/plugins/discourse-spoiler-alert/lib/apply-spoiler", "discourse/lib/to-markdown"], function (_exports, _pluginApi, _composer, _applySpoiler, _toMarkdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initializeSpoiler = initializeSpoiler;

  function spoil(element) {
    element.querySelectorAll(".spoiler").forEach(spoiler => {
      spoiler.classList.remove("spoiler");
      spoiler.classList.add("spoiled");
      (0, _applySpoiler.default)(spoiler);
    });
  }

  function initializeSpoiler(api) {
    api.decorateCookedElement(spoil, {
      id: "spoiler-alert"
    });
    api.addToolbarPopupMenuOptionsCallback(() => {
      return {
        action: "insertSpoiler",
        icon: "magic",
        label: "spoiler.title"
      };
    });

    _composer.default.reopen({
      actions: {
        insertSpoiler() {
          this.get("toolbarEvent").applySurround("[spoiler]", "[/spoiler]", "spoiler_text", {
            multiline: false,
            useBlockMode: true
          });
        }

      }
    });

    (0, _toMarkdown.addTagDecorateCallback)(function () {
      if (this.element.attributes.class === "spoiled") {
        this.prefix = "[spoiler]";
        this.suffix = "[/spoiler]";
      }
    });
    (0, _toMarkdown.addBlockDecorateCallback)(function (text) {
      const {
        name,
        attributes
      } = this.element;

      if (name === "div" && attributes.class === "spoiled") {
        this.prefix = "[spoiler]";
        this.suffix = "[/spoiler]";
        return text.trim();
      }
    });
  }

  var _default = {
    name: "spoiler-alert",

    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");

      if (siteSettings.spoiler_enabled) {
        (0, _pluginApi.withPluginApi)("1.3.0", initializeSpoiler);
      }
    }

  };
  _exports.default = _default;
});
define("discourse/plugins/discourse-spoiler-alert/lib/apply-spoiler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = applySpoiler;
  const INTERACTIVE_SELECTOR = ["a", "area", "audio", "button", "details", "embed", "iframe", "img.animated", "input", "map", "object", "option", "portal", "select", "textarea", "track", "video", ".lightbox"].join(", ");

  function isInteractive(event) {
    return event.defaultPrevented || event.target.closest(INTERACTIVE_SELECTOR);
  }

  function applySpoiler(element) {
    element.setAttribute("data-spoiler-state", "blurred");
    element.classList.add("spoiler-blurred");
    element.addEventListener("click", event => {
      if (element.getAttribute("data-spoiler-state") === "blurred") {
        element.setAttribute("data-spoiler-state", "revealed");
        element.classList.remove("spoiler-blurred");
        event.preventDefault();
      } else if (!isInteractive(event)) {
        element.setAttribute("data-spoiler-state", "blurred");
        element.classList.add("spoiler-blurred");
      }
    });
  }
});
define("discourse/plugins/discourse-spoiler-alert/lib/discourse-markdown/spoiler-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  const CONTAINS_BLOCK_REGEX = /\n|<img|!\[[^\]]*\][(\[]/;

  function insertSpoiler(_, spoiler) {
    const element = CONTAINS_BLOCK_REGEX.test(spoiler) ? "div" : "span";
    return "<".concat(element, " class='spoiler'>").concat(spoiler, "</").concat(element, ">");
  }

  function replaceSpoilers(text) {
    text = text || "";

    while (text !== (text = text.replace(/\[spoiler\]((?:(?!\[spoiler\]|\[\/spoiler\])[\S\s])*)\[\/spoiler\]/gi, insertSpoiler))) {}

    return text;
  }

  function setupMarkdownIt(helper) {
    helper.registerOptions((opts, siteSettings) => {
      opts.features["spoiler-alert"] = !!siteSettings.spoiler_enabled;
    });
    helper.registerPlugin(md => {
      md.inline.bbcode.ruler.push("spoiler", {
        tag: "spoiler",
        wrap: "span.spoiler"
      });
      md.block.bbcode.ruler.push("spoiler", {
        tag: "spoiler",
        wrap: "div.spoiler"
      });
    });
  }

  function setup(helper) {
    helper.allowList(["span.spoiler", "div.spoiler"]);

    if (helper.markdownIt) {
      setupMarkdownIt(helper);
    } else {
      helper.addPreProcessor(replaceSpoilers);
    }
  }
});

